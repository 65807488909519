// Import package from Soil plugin example.
import $ from "@modules/jquery";

$(function () {
  $("ul.mainmenu > li").each(function () {
    let maxHeight = 0;
    const uls = $("ul", $(this));
    uls.each(function () {
      maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
    });

    uls.height(maxHeight);
  });
});
